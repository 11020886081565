import React, { useEffect, useState } from "react";
import { About } from "./about/About";
import { Contact } from "./contact/Contact";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { ImageSection } from "./imageSection/ImageSection";
import { Product } from "./product/Product";

function App() {
  const [imageOverScrolled, setImageOverScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    let elementPosition = document.getElementById("kristina-logo");
    let offTop = elementPosition?.offsetTop;
    let offHeight = elementPosition?.offsetHeight;
    let elemSum = offTop && offHeight ? offTop + offHeight : 0;

    setImageOverScrolled(winScroll > elemSum);
  };

  return (
    <>
      <Header logoVisible={!imageOverScrolled} />
      <ImageSection />
      <About />
      <Product />
      <hr />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
