import React from "react";
import "./contact.scss";
import { Container, Row, Col } from "react-grid-system";

export const Contact = () => {
  return (
    <Container className="category-section contact">
      <Row>
        <Col sm={2}>
          <h1 id="contact">Kontakt</h1>
        </Col>
        <Col className="textBox">
          <h3>Neugierig?</h3>
          <p>Melden Sie sich gerne bei mir:</p>
          <p>Kristina Schott</p>
          <a href="mailto:kristina@kristina-label.ch">
            kristina@kristina-label.ch
          </a>
        </Col>
      </Row>
    </Container>
  );
};
