import React from "react";
import { Row, Col } from "react-grid-system";

export const PriceNDescription = () => {
  return (
    <Row>
      <Col>
        <p className="price-tag">Preis: 247,95CHF</p>
        <p className="product-description-heading">
          Camoline - Ein Set für Eleganz und zeitlosen Stil.{" "}
        </p>
        <p className="product-description-text">
          Das beschreibt die erste Kollektion meines Labels KRISTINA. Sie
          besteht aus einer Männerfliege, einem Einstecktuch und einem passenden
          Accessoire für die Frau. Dieses kann vielseitig verwendet werden
          (Beispielsweise als Halstuch, Haarband, etc.). Bei der Herstellung in
          100% Handarbeit wurde auf hochwertige und reine Seide gesetzt, um dem
          Design und meinem Wertigkeitsanspruch gerecht zu werden. Alle Sets
          zeichnen sich durch ein individuelles Maß aus - dadurch sind sie ein
          Unikat! <br />
          <br /> Verwenden Sie bitte das untenstehende Anfrageformular um die
          genauen Maße/ Verfügbarkeiten und weitere Informationen Ihres
          Wunschsets zur erhalten.
          <br />
          <br /> KRISTINA.
        </p>
      </Col>
    </Row>
  );
};
