import React from "react";
import "./footer.scss";
import { IoMdHeartEmpty } from "react-icons/io";

export const Footer = () => {
  return (
    <div className="category-section footer">
      <p>
        © 2021 Kristina. Made with{"  "}
        <p className="heart_icon">
          <IoMdHeartEmpty />
        </p>
      </p>
    </div>
  );
};
