import React, { useState } from "react";
import "./request-form.scss";
import Select from "react-select";

type RequestFormProps = {
  data: {
    name: string;
    images: { data: any }[];
  }[];
};

export const RequestForm = ({ data }: RequestFormProps) => {
  let dataset = [
    "Camorossi",
    "Camostone",
    "Camoroyal",
    "Camodeepsea",
    "Camoforest",
    "Camobaux",
  ];
  let options = dataset.map((data) => {
    return {
      value: data.toLowerCase(),
      label: data,
    };
  });

  const [val, setVal]: any = useState({ value: "", label: "" });

  return (
    <div>
      <h1>Anfrageformular</h1>
      <Select
        options={options}
        placeholder="Farbe wählen..."
        onChange={(val) => setVal(val)}
        className="select-box"
      />
      <button
        className={`request-btn ${(!val || !val.value) && "disabled"}`}
        onClick={() => {
          val.label
            ? (window.location.href = `mailto:kristina@kristina-label.de?subject=Anfrage zu ${val.label}&body=Hi Kristina, ich interessiere mich für ... in der Farbe ${val.label}...`)
            : alert("Bitte wählen Sie eine Farbe aus!");
        }}
      >
        Anfrage senden
      </button>
    </div>
  );
};
