import React from "react";

interface LinkProps {
  target?: string;
  text: string;
}

export const Link = ({ target, text }: LinkProps) => {
  return (
    <a className="heading-links" href={target ? target : text.toLowerCase()}>
      {text.toUpperCase()}
    </a>
  );
};
