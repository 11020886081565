import React from "react";
import Bild from "../assets/ak-min.jpg";
import Bild2 from "../assets/juk-min 2.jpg";
import { Col, Container, Row } from "react-grid-system";
import "./about.scss";
import { ImageText } from "./ImageText";

export const About = () => {
  return (
    <Container className="category-section">
      <Row>
        <Col sm={2}>
          <h1 id="about">About</h1>
        </Col>
        <Col className="textBox">
          <h3>Das Besondere und das Einzigartige</h3>
          <p>
            Was ist das Besondere oder das Einzigartige? Der Schriftsteller
            Oscar Wilde brachte es auf den Punkt: „Das Durchschnittliche gibt
            der Welt ihren Bestand, das Außergewöhnliche ihren Wert.“
            Außergewöhnlich sein, hervorstechen und sich dabei im Mittelpunkt
            des Geschehens wohlfühlen und exakt dies ist mein Ziel, indem ich
            mich mit außergewöhnlichen modischen Accessoires beschäftige. Die
            Philosophie von Coco Chanel, einem meiner Vorbilder, war es, dass
            Mode eine Kunstform und kein Standardprodukt ist, welches schnell
            und ohne Leidenschaft hergestellt werden kann. Zur damaligen Zeit
            war es für sie schwierig ihre Pläne durchzusetzen, aus dem Grund,
            dass schöne Kleidung nicht immer mit dem modischen Stereotyp
            einhergehen musste. Heute spricht jeder von Individualisierung und
            den neusten Trends von Influencern. Sie denken womöglich, dass der
            Erfolg dahinter keine große Herausforderung darstelle, jedoch habe
            ich aus meinem Umfeld viele Geschichten von Freunden, Kollegen und
            Bekannten gehört, die sich nicht auf diese Weise mit Mode
            beschäftigen. Für einige ist es einfach nur ein Gebrauchsgegenstand
            oder ein Kleidungsstück welches, gerade extrem angesagt ist und
            günstig bei einer Modekette zu bekommen war. Dies ist meiner Meinung
            nach völlig legitim und auch vertretbar, aber könnte es schaden den
            Horizont zu erweitern? Mein Name ist Kristina und ich möchte Sie
            dazu einladen an eben dieser Begeisterung teilzuhaben.
          </p>
        </Col>
      </Row>
      <ImageText
        heading="Attraktivität durch Stil"
        text="Kleidung und Mode inspirieren mich jeden Tag aufs Neue. Ich würde
          sogar so weit gehen zu sagen, dass ich mich am liebsten den ganzen Tag
          mit Stoffen und neuen Ideen beschäftigen würde. Mir geht es
          hauptsächlich darum meine Ideen zu etwas optisch und haptisch Schönem
          umzusetzen. Die Arbeit mit meinen Händen ist dabei von essenzieller
          Bedeutung, da Kunst durch Handarbeit erst recht die Bezeichnung
          „Kunst“ verdient. Weg von der Idee zu kommen, Accessoires nur als
          Kleidungs- oder Handwerkserzeugnis zu betrachten, ist mir persönlich
          dabei sehr wichtig. Ich möchte die Idee weiterdenken, ein Accessoire
          als Teil von Ihnen und als Ihren persönlichen Begleiter zu sehen."
        image={Bild}
        imageAlign="left"
      />
      <ImageText
        heading=""
        text="Doch richtig attraktiv werden Menschen meiner Meinung nach erst, wenn sie ihren persönlichen Stil gefunden haben. Ausstrahlung, Sympathie und Individualität sind hier die drei Stichpunkte, die ich gerne in den Vordergrund stellen möchte. Accessoires helfen ungemein dabei. Ich möchte zeigen, dass oft nur Kleinigkeiten ausreichen, um der Attraktivität den letzten Schliff zu geben, damit der Diamant nicht nur hübsch aussieht, sondern auch zu funkeln beginnt. "
        image={Bild2}
        imageAlign="right"
      />

      <Col className="textBox" offset={{ md: 2, lg: 2 }} md={10} lg={7}>
        <h3>Accessoires für jede Gelegenheit</h3>
        <p>
          Wenn ich mich daran erinnere, welche besonderen Events mir in
          Erinnerung geblieben sind, dann sind es diese, wo die Stimmung und die
          äußeren Einflüsse gut harmoniert haben. Insbesondere auf meiner ersten
          Berliner Fashion Week wurde dies deutlich. Mir gefiel die Präsentation
          der Mode, das Auftreten der Models, welche die neusten Kollektionen
          den neugierigen Blicken der Besucher zur Schau stellten. Hohe
          Aufmerksamkeit bekamen diejenigen mit besonders ausgefallenen
          Kleidungsstücken. Da war mir klar, dass ich dies auch leben möchte.
          Etwas Besonderes erschaffen. Etwas modernes, schickes und
          individuelles.
        </p>
      </Col>
    </Container>
  );
};
