import React, { useLayoutEffect, useState } from "react";
import { IoIosMenu } from "react-icons/io";
import LogoBlack from "../assets/Logo_Kristina_final_black.jpg";
import "./header.scss";
import { Linklist } from "./Linklist";
import ToggledMenu from "./ToggledMenu/ToggledMenu";

type HeaderProps = {
  logoVisible: Boolean;
};

export const Header = ({ logoVisible }: HeaderProps) => {
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  console.log(logoVisible);

  const [visibleMenu, setVisibleMenu] = useState(false);

  let [width] = useWindowSize();

  if (width >= 671) {
    return (
      <nav>
        <div className={"nav-section" + (!logoVisible ? " header-logo" : "")}>
          {!logoVisible && <img src={LogoBlack} alt="Kristina Logo" />}
          <Linklist closeMenu={() => setVisibleMenu(!visibleMenu)} />
          {!logoVisible && <div />}
        </div>
      </nav>
    );
  }
  return (
    <nav>
      <div className={"nav-section" + !logoVisible ? " header-logo" : ""}>
        {!logoVisible ? (
          <img src={LogoBlack} className="black-logo" alt="Kristina Logo" />
        ) : (
          <div />
        )}
        <ToggledMenu visible={visibleMenu} />
        <button
          className="menu-toggle"
          onClick={() => {
            setVisibleMenu(!visibleMenu);
          }}
        >
          <IoIosMenu className="hello" />
        </button>
      </div>
    </nav>
  );
};
