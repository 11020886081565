import React from "react";
import "./product.scss";
import { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import Logo2 from "../assets/AS_KRISTINA02.jpg";
import Logo3 from "../assets/AS_KRISTINA05.jpg";
import Logo4 from "../assets/AS_KRISTINA14.jpg";
import Logo5 from "../assets/AS_KRISTINA16.jpg";
import Logo6 from "../assets/AS_KRISTINA20.jpg";
import Logo7 from "../assets/AS_KRISTINA21.jpg";
import Logo8 from "../assets/AS_KRISTINA22.jpg";
import Logo9 from "../assets/AS_KRISTINA26.jpg";
import Logo10 from "../assets/AS_KRISTINA27.jpg";
import { PriceNDescription } from "./PriceNDescription";
import { RequestForm } from "./RequestForm";

export const Product = () => {
  let dataset: {
    name: string;
    images: { data: any }[];
  }[] = [
    {
      name: "Camoline",
      images: [
        { data: Logo10 },
        { data: Logo2 },
        { data: Logo3 },
        { data: Logo5 },
        { data: Logo8 },
        { data: Logo4 },
        { data: Logo9 },
        { data: Logo6 },
        { data: Logo7 },
      ],
    },
  ];

  const [activeModel, setActiveModel] = useState(dataset[0]);

  const changeActiveItem = (item: any) => {
    let choosenItem = dataset.find((data) => data.name === item.name);
    if (choosenItem) {
      setActiveModel(choosenItem);
    }
  };

  return (
    <Container className="category-section products">
      <Row>
        <Col sm={2}>
          <h1 id="product">Produkte</h1>
        </Col>
        <Col offset={{ sm: 2, md: 1, lg: 0 }} sm={6} className="button-box">
          {dataset.map((data) => (
            <button
              className={`category-name ${
                data.name === activeModel.name ? "active" : ""
              }`}
              onClick={() => changeActiveItem(data)}
            >
              {data.name}
            </button>
          ))}
        </Col>
      </Row>
      <Row>
        {activeModel.images.map((image) => (
          <Col
            offset={{ sm: 2, lg: 1 }}
            sm={10}
            md={5}
            lg={3}
            className="product-image"
          >
            <img src={image.data} alt={activeModel.name} />
          </Col>
        ))}
      </Row>
      <PriceNDescription />
      <RequestForm data={dataset} />
    </Container>
  );
};
