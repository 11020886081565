import React from "react";
import backgroundImage from "../assets/title-min.jpg";
import logo_white from "../assets/Logo_Kristina_white.png";
import "./imageSection.scss";

export const ImageSection = () => {
  return (
    <div className="imageSection">
      <div className="overlay">
        <div>
          <img id="kristina-logo" src={logo_white} alt="Kristina Design" />
        </div>
      </div>
      <img src={backgroundImage} alt="Kristina Design" />
    </div>
  );
};
