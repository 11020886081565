import React, { Component } from "react";
import "./header.scss";
import { Link } from "./Link";

export class Linklist extends Component {
  render() {
    return (
      <div className="linklist1">
        <Link target="/" text="Start" />
        <Link target="#about" text="About" />
        <Link target="#product" text="Produkt" />
        <Link target="#contact" text="Kontakt" />
      </div>
    );
  }
}
