import React, { Component } from "react";
import { Linklist } from "../Linklist";
import "./toggledmenu.css";

export class ToggledMenu extends Component {
  render() {
    let propName;
    if (this.props.visible) {
      propName = "toggled-menu";
    } else {
      propName = "hidden-menu";
    }
    return (
      <div className={propName}>
        <Linklist
          closeMenu={() => {
            this.props.closeMenu();
          }}
        />
      </div>
    );
  }
}

export default ToggledMenu;
