import React from "react";
import { Row, Col } from "react-grid-system";
import "./image-section.scss";

type ImageTextProps = {
  heading?: string;
  text: string;
  image: any;
  imageAlign: string;
};

export const ImageText = ({
  heading,
  text,
  image,
  imageAlign,
}: ImageTextProps) => {
  return (
    <Row align="center" className="image-section">
      <Col md={2} lg={1}></Col>
      {imageAlign === "left" && (
        <Col className="about-image left" md={10} lg={4}>
          <img src={image} alt="Bärtiger Mann mit Fliege am Hemd" />
        </Col>
      )}
      <Col className="textBox" offset={{ md: 2, lg: 1 }} md={10} lg={6}>
        {heading && <h3>{heading}</h3>}
        <p>{text}</p>
      </Col>
      {imageAlign === "right" && (
        <Col className="about-image" offset={{ md: 2, lg: 0 }} md={10} lg={4}>
          <img src={image} alt="Zwei Frauen mit Fliege im Haar" />
        </Col>
      )}
    </Row>
  );
};
